@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #323235;
  --mycolor:white;
  --cn-color1:#1A7FC3;
  --cn-color2:#FF7F00;
}

@font-face {
  font-family: "sofia pro";
  src: url("/src/fonts/Sofia\ Pro\ Black\ Az.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

.sofia-font {
  font-family: "sofia pro", sans-serif;
}

body {
  margin: 0;
}

.main-layout {
  z-index: 1;
}

/* globals.css */
@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.ticker-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.ticker-content {
  display: inline-block;
  padding-left: 100%; /* Ensures the text starts off screen */
  animation: ticker 40s linear infinite; /* Adjust the duration as needed */
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.zoom-in {
  animation: zoomIn 3s forwards;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}
