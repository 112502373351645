#discreption{

    @media screen and (max-width: 80rem){
        display: none !important;
    }
}

#projectimage{ 
    @media screen and (max-width: 80rem){
        display: flex;
        align-items: center;
        margin: auto;
        justify-content: center;
        width: 30rem !important;
    }
}

.container.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* border: 2px solid white; */
  margin-left: 18rem;

  @media screen and (max-width: 100rem){
    margin-left: 12rem;
  }
}

.team_container{
  @media screen and (max-width: 700px){
    margin-top: 17rem !important;
  }
}

.member-img img {
  border-radius: 10px;
  overflow: hidden;
  height: 26rem;
  width: 25rem;
  align-items: center;
  justify-content: center;
  

}

 .social {
  position: absolute;
  left: 0;
  top: -18px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  padding-right: 3rem;
  margin-left: 6rem;
  margin-top: 1rem;
  z-index: 3;
}

.team .team-member .social a {
  transition: color 0.3s;
  color: white;
  /* background: rgb(170, 105, 20); */
  margin: -10px 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: 0.3s;
}

.team .team-member .social a img {
  line-height: 0;
  font-size: 16px;
}

.team .team-member .social a:hover {
  background: color-mix(in srgb, var(--accent-color) 90%, black 15%);
}

.team .team-member .social img {
  font-size: 18px;
  margin: 0 2px;
}

.team .team-member .member-info {
  padding: 30px 13px;
  text-align: center;
  box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 92%);
  background: #a0d1fe;
  margin: -40px 22px 0 10px;
  position: relative;
  border-radius: 8px;
  color: white;
  font-family: Avant Garde Medium BT;
  align-items: center;
  justify-content: center;
  width: 24rem;
}

.team .team-member .member-info h4 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 25px;
  clip-path: circle(60%);
  color: rgb(62, 47, 3);
  font-family: Avant Garde Medium BT;
}

.team .team-member .member-info span {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: color-mix(in srgb, rgb(248, 251, 249), transparent 30%);
}

.team .team-member .member-info p {
  font-style: Avant Garde Medium BT;
  font-size: 16px;
  line-height: 26px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.team .team-member:hover .social {
  opacity: 1;
}


.sharehold {
  height: 7rem;
  margin-top: -1rem;
  display: block;
  align-items: center;
  justify-content: center;
  margin-left: 0rem;
  box-shadow: inset;
  padding-top: 2rem;
  width: 20rem;
  margin: -40px 22px 0 10px;
}

