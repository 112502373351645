:root {
  --black: #323235;
  --mycolor: white;
  --cn-color1: #1a7fc3;
  --cn-color2: #ff7f00;
}

.video-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.video {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  filter: blur(2px);
}

.content {
  position: relative;
  z-index: 3;
  /* color: white; */
  text-align: center;
  top: 0;
  width: 70%;
  min-height: 20rem;
  margin: 15% auto;
  padding: 20px;
}

.serv_btn,
.wrk_btn {
  min-width: 10rem;
  min-height: 3rem;
  border-radius: 2rem;
  color: white;
  background-color: var(--cn-color1);
}

.cool-btn {
  border-radius: 2rem;
}

.wrk_btn {
  background-color: var(--cn-color2);
  color: white;
}

.web_div {
  background-image: url("../assets/what_we_do/10507204.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.skeleton-paragraph-1 .ant-skeleton-paragraph > li:nth-child(1) {
  background-color: rgb(117, 111, 111);
}

.skeleton-paragraph-1 .ant-skeleton-paragraph > li:nth-child(2) {
  background-color: rgb(117, 111, 111)
}

.skeleton-paragraph-1 .ant-skeleton-paragraph > li:nth-child(3) {
  background-color: rgb(117, 111, 111)
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#cc3f3f 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  33% {background-size: 20% 10% ,20% 100%,20% 100%}
  50% {background-size: 20% 100%,20% 10% ,20% 100%}
  66% {background-size: 20% 100%,20% 100%,20% 10% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}